import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useSpeech } from "../SpeechContext";
import RepeatWordIntervention from "../../components/RepeatWordIntervention";
import { v4 as uuidv4 } from "uuid";
import { RepeatWordInterventionMode } from "../../components/RepeatWordIntervention/RepeatWordIntervention";
import SpellOutIntervention from "../../components/SpellOutIntervention";
import RhymeIntervention from "../../components/RhymeIntervention";
import { SpellOutBy } from "../../components/SpellOutIntervention/SpellOutIntervention";
import useSpeechErrors from "../../hooks/useSpeechErrors";
import JigsawPuzzleIntervention from "../../components/JigsawPuzzleIntervention";
import { DragSpeakIntervention } from "../../components/DragSpeakIntervention/DragSpeakIntervention";

const repeatWordInterventionType = RepeatWordInterventionMode.WORD;

export enum InterventionStatus {
  PENDING = "pending",
  IN_PROGRESS = "in-progress",
  COMPLETED = "completed",
}

export interface IIntervention {
  id: string;
  label: string;
  status: InterventionStatus;
  type: InterventionType;
  word: string;
  component: React.ReactElement;
  phonemes: string;
  graphemes: string;
  syllables: string;
}

export enum InterventionType {
  RHYME = "rhyme",
  JIGSAW = "jigsaw",
  REPEAT = "repeat",
  SPELLOUT = "spell-out",
  DRAG_SPEAK = "drag-and-speak",
  UNKNOWN = "unknown",
}

const SINGLE_SYLLABLES = [
  InterventionType.RHYME,
  InterventionType.JIGSAW,
  InterventionType.DRAG_SPEAK,
];
const MULTI_SYLLABLES = [
  InterventionType.REPEAT,
  InterventionType.SPELLOUT,
  InterventionType.DRAG_SPEAK,
];

export interface IInterventionsContext {
  interventions: IIntervention[];
  setInterventions: React.Dispatch<any>;
  createInterventions: () => any;
}

const InterventionsContext = createContext<IInterventionsContext | undefined>(
  undefined
);

interface InterventionsProviderProps {
  children: ReactNode;
}

export const InterventionsProvider: React.FC<InterventionsProviderProps> = ({
  children,
}) => {
  const { isMispronunciationError } = useSpeechErrors();

  const { setHasInterventions, currentSpeechResult, nextWordIndex } =
    useSpeech();
  const [interventions, setInterventions] = useState([]);

  useEffect(() => {
    const hasPendingInterventions = interventions.some(
      (intervention: any) => intervention.status === InterventionStatus.PENDING
    );

    const errors = currentSpeechResult.filter((item: any) => {
      return isMispronunciationError(item);
    });

    if (errors.length > 0 && !hasPendingInterventions) {
      createInterventions();
    }
  }, [currentSpeechResult]);

  useEffect(() => {
    setHasInterventions(
      interventions.length > 0 &&
        interventions.some(
          (intervention: any) =>
            intervention.status === InterventionStatus.PENDING
        )
    );
  }, [interventions]);

  const createInterventions = () => {
    const seenWords: { [key: string]: any } = {};

    const ignoreWords = ["the", "a", "an", "but", "so", "if"];

    const errors = currentSpeechResult.filter((item: any) => {
      if (ignoreWords.includes(item?.Word)) {
        return false;
      }

      if (seenWords[item?.Word]) {
        return false;
      }

      if (item?.index > nextWordIndex) {
        return;
      }

      seenWords[item?.Word] = item;
      return isMispronunciationError(item);
    });

    const newInterventions = errors
      .map((item: any) => {
        const word = item?.Word;
        const id = uuidv4();
        const syllables = getSyllables(item);
        const phonemes = getPhonemes(item);
        const graphemes = getGraphemes(item);

        const sharedInterventionProps = {
          fullResult: item,
          id,
          word,
          syllables,
          phonemes,
          graphemes,
          status: InterventionStatus.PENDING,
          component: (
            <RepeatWordIntervention id={id} type={repeatWordInterventionType} />
          ),
        };

        const interventionsObjects = [
          {
            ...sharedInterventionProps,
            label: "Repeat after me",
            type: InterventionType.REPEAT,
            component: (
              <RepeatWordIntervention
                id={id}
                type={repeatWordInterventionType}
              />
            ),
          },
          {
            ...sharedInterventionProps,
            label: "Repeat the spelled out word",
            type: InterventionType.SPELLOUT,
            component: (
              <SpellOutIntervention type={SpellOutBy.LETTER} id={id} />
            ),
          },
          {
            ...sharedInterventionProps,
            label: "Drag and Speak",
            type: InterventionType.DRAG_SPEAK,
            component: <DragSpeakIntervention id={id} />,
          },
          {
            ...sharedInterventionProps,
            label: "Rhyme game",
            type: InterventionType.RHYME,
            component: <RhymeIntervention id={id} />,
          },
          {
            ...sharedInterventionProps,
            label: "Jigsaw",
            type: InterventionType.JIGSAW,
            component: <JigsawPuzzleIntervention id={id} />,
          },
        ];
        const amountOfSyllables =
          typeof syllables === "string" ? syllables?.split(" ").length : 1;
        amountOfSyllables;

        // return interventionsObjects[0];
        const interventionsAvailable = interventionsObjects.filter((item) => {
          return amountOfSyllables <= 1
            ? SINGLE_SYLLABLES.includes(item.type)
            : MULTI_SYLLABLES.includes(item.type);
        });
        return interventionsAvailable[
          Math.floor(Math.random() * interventionsAvailable.length)
        ];
      })
      .filter((item: any) => item !== null);

    setInterventions(newInterventions);

    return newInterventions;
  };

  const getSyllables = (item: any) => {
    const syllables = item?.Syllables;
    return syllables
      ? syllables.map((item: any) => item.Syllable).join(" ")
      : [];
  };

  const getGraphemes = (item: any) => {
    const syllables = item?.Syllables;
    return syllables
      ? syllables.map((item: any) => item.Grapheme).join(" ")
      : [];
  };

  const getPhonemes = (item: any) => {
    const phonemes = item?.Phonemes;
    return phonemes ? phonemes.map((item: any) => item.Phoneme).join(" ") : [];
  };

  return (
    <InterventionsContext.Provider
      value={{
        interventions,
        setInterventions,
        createInterventions,
      }}
    >
      <>{children}</>
    </InterventionsContext.Provider>
  );
};

export const useInterventions = () => {
  const context = useContext(InterventionsContext);
  if (context === undefined) {
    throw new Error(
      "useInterventions must be used within a InterventionsProvider"
    );
  }
  return context;
};
