import { dictionary } from "cmu-pronouncing-dictionary";
import { getChatCompletion } from "../utils/api";
import { useEffect, useRef, useState } from "react";

const hardcodedMappings: { [key: string]: string[] } = {
  twirled: ["t", "w", "ir", "l", "ed"],
};

export const useWordGraphemes = (word: string) => {
  const initialized = useRef<any>("");
  const [graphemes, setGraphemes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const phonemes = dictionary[word]
    .replace(/\d/g, "")
    .split(" ")
    .map((phoneme) => phoneme.toLowerCase());

  useEffect(() => {
    if (!word || word === initialized.current) {
      return;
    }
    // words that chatgpt struggles with
    if (hardcodedMappings[word]) {
      setGraphemes(hardcodedMappings[word]);
      setLoading(false);
      return;
    }
    initialized.current = word;
    setLoading(true);

    Promise.all(
      Array.from({ length: 3 }, () =>
        getChatCompletion(
          `given the word "${word}", it's phonemes are [${phonemes}], give me the ideal graphemes for those phonemes, ` +
            // `the returned graphemes array must have a length of ${phonemes.length}, make sure the length is the same even if graphemes need to be grouped. ` +
            `the returned graphemes array must have a length of ${phonemes.length}, make sure the length is the same by grouping repeated graphemes in a row. ` +
            `The graphemes must never contain letters that are not present in the word "${word}", and the graphemes you choose when concatenated should form exactly the word "${word}". ` +
            `Return in JSON format { graphemes: string[] }.`
        )
      )
    )
      .then((responses) => {
        const [result] = responses
          .map(
            (response) =>
              JSON.parse(response.data.choices[0].message.content).graphemes
          )
          .filter((graphemes) => graphemes.length === phonemes.length);
        setGraphemes(
          result ??
            JSON.parse(responses[0].data.choices[0].message.content).graphemes
        );
        // setGraphemes(
        //   JSON.parse(response.data.choices[0].message.content).graphemes
        // );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [word]);

  return {
    loading,
    graphemes,
  };
};
