import { Navigate, Route, Routes } from "react-router-dom";
import "./styles.css";
import Home from "./pages/Home";
import Lesson from "./pages/Lesson";
import { AudioProvider } from "./context/AudioRecordingContext";
import { AuthProvider, useAuth } from "./context/AuthContext";
import SignIn from "./pages/Auth/SignIn";
import { ReactNode } from "react";
import { ModalProvider } from "./context/ModalContext";
import { InterventionsProvider } from "./context/InterventionsContext";
import { SpeechProvider } from "./context/SpeechContext";
import Score from "./pages/Score";
import { LessonProvider } from "./context/LessonContext";
import { AvatarClient, AvatarProvider } from "alpha-ai-avatar-sdk-react";
import Assessment from "./components/Assessment";
import FullScorePage from "./pages/FullScore/FullScore";
import { AppConfigProvider } from "./context/AppConfig";
import SignUp from "./pages/Auth/SignUp";
import NamingLetters from "./components/Assessment/NamingLetters";
import NamingLetterScore from "./pages/NamingLetterScore/NamingLetterScore";

const AVATAR_KEY = import.meta.env.VITE_AVATAR_KEY;
const BASE_URL = import.meta.env.VITE_AVATAR_BASE_URL;

const client = new AvatarClient({ apiKey: AVATAR_KEY, baseUrl: BASE_URL });

const ProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const queryString = window.location.search;
  const { userId } = useAuth();

  if (!userId) {
    return <Navigate to={`/sign-in${queryString}`} replace />;
  }

  return children;
};

function App() {
  return (
    <AppConfigProvider>
      <AuthProvider>
        <LessonProvider>
          <AvatarProvider client={client}>
            <SpeechProvider>
              <InterventionsProvider>
                <ModalProvider>
                  <AudioProvider>
                    <Routes>
                      <Route path="/sign-in" element={<SignIn />} />
                      <Route path="/sign-up" element={<SignUp />} />
                      <Route
                        path="/"
                        element={
                          <ProtectedRoute>
                            <Home />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/lesson"
                        element={
                          <ProtectedRoute>
                            <Lesson />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/assessment"
                        element={
                          <ProtectedRoute>
                            <Assessment />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/full-score"
                        element={
                          <ProtectedRoute>
                            <FullScorePage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/score"
                        element={
                          <ProtectedRoute>
                            <Score />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/naming-letter-score"
                        element={
                          <ProtectedRoute>
                            <NamingLetterScore />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/naming-letters"
                        element={
                          <ProtectedRoute>
                            <NamingLetters />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </AudioProvider>
                </ModalProvider>
              </InterventionsProvider>
            </SpeechProvider>
          </AvatarProvider>
        </LessonProvider>
      </AuthProvider>
    </AppConfigProvider>
  );
}

export default App;
