import { useEffect, useState } from "react";
import { useSpeech } from "../../context/SpeechContext";
import {
  IIntervention,
  InterventionStatus,
  useInterventions,
} from "../../context/InterventionsContext/InterventionsContext";
import checkCheckPng from "../../assets/check-check.png";
import { RichAvatar } from "../Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import { useConversation } from "../../hooks/useConversation";
import JigsawPuzzle from "../JigsawPuzzle/JigsawPuzzle";
import { wrapPhoneme } from "../../utils/avatarMessage";
import { dictionary } from "cmu-pronouncing-dictionary";
import Spinner from "../Spinner";
import Window from "../Window";
import StepsProgressBar from "../StepsProgressBar";
import { useLesson } from "../../context/LessonContext";
import phrasesMock from "../../mock/phrases";
import MicButton from "../MicButton";
import { useWordGraphemes } from "../../hooks/useWordGraphemes";

function JigsawPuzzleIntervention({ id }: { id: string }) {
  const totalLessons = phrasesMock.length - 1;
  const { interventions, setInterventions } = useInterventions();
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const [interventionLoaded, setInterventionLoaded] = useState(false);
  // const [puzzlePiecesStrings, setPuzzlePiecesStrings] = useState<string[]>([]);
  // const [loading, setLoading] = useState(true);
  const { lessonStep, blockUserSpeech, setBlockUserSpeech } = useLesson();

  const { currentSpeechResult } = useSpeech();
  const currentIntervention = interventions.find(
    (intervention: IIntervention) => {
      return intervention.id === id;
    }
  )!;

  const updateInterventions = () => {
    const updatedInterventions = interventions.map((intervention: any) => {
      if (intervention.id === id) {
        return {
          ...intervention,
          status: InterventionStatus.COMPLETED,
        };
      }
      return intervention;
    });
    setInterventions([...updatedInterventions]);
  };

  useEffect(() => {
    if (!interventionLoaded) {
      setInterventionLoaded(true);
      return;
    }
  }, [currentSpeechResult]);

  const { word } = currentIntervention;

  const { loading, graphemes: puzzlePiecesStrings } = useWordGraphemes(word);

  const phonemes = dictionary[word]
    .replace(/\d/g, "")
    .split(" ")
    .map((phoneme) => phoneme.toLowerCase())
    .join(" ");

  useEffect(() => {
    setBlockUserSpeech(true);
  }, [word]);

  const { say } = useAvatar();

  const { cancelConversation } = useConversation(
    {
      queue: [
        () => {
          say(
            `Let’s practice the word ${word}. Drag the sounds on the puzzle pieces into the middle to create the word.`
          );
        },
      ],
    },
    currentIntervention.word
  );

  const onCorrectDrop = (id: number) => {
    cancelConversation();
    const phoneme = phonemes.split(" ")[id];
    let alphabet = "sapi";

    if (
      ["hh"].some((item) => {
        return phoneme === item;
      })
    ) {
      alphabet = "ipa";
    }

    say(wrapPhoneme(phonemes.split(" ")[id], alphabet), {
      prosody: { rate: "-30%" },
    });
    if (puzzlePiecesStrings.length === id + 1) {
      setTimeout(() => {
        setShowCompletedMessage(true);
        cancelConversation();
        say("Nice work!");
        setTimeout(() => {
          setBlockUserSpeech(false);
          setShowCompletedMessage(false);
          updateInterventions();
        }, 2000);
      }, 1000);
    }
  };

  const onIncorrectDrop = () => {
    say("That's not quite right. Try again.");
  };

  return (
    <div className="min-h-[400px]  z-10 flex flex-col  h-full justify-start items-center">
      <Window noPadding className="w-[690px] py-6 px-8 mt-4">
        <div className="flex">
          <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
            <RichAvatar size="120" message={word} />
          </div>
          <div className="w-full flex flex-col">
            <StepsProgressBar
              steps={totalLessons + 1}
              currentStep={lessonStep}
            />

            <div className="flex justify-between align-center h-full">
              <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                <span className="">Practice</span>
              </div>
              <MicButton
                blockUserSpeech={blockUserSpeech}
                micButtonIsEnabled={true}
              />
            </div>
          </div>
        </div>
      </Window>

      <div
        className={`
          ${
            showCompletedMessage
              ? "bg-green-gradient border-dark-green flex-col"
              : "bg-white-gradient border-semi-white"
          }
          mt-4 flex justify-center items-center min-w-[690px] min-h-[336px] py-6 px-8 h-full justify-center items-center rounded-[24px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px]`}
      >
        {puzzlePiecesStrings.length === 0 || loading ? (
          <Spinner />
        ) : (
          <JigsawPuzzle
            syllables={puzzlePiecesStrings}
            onCorrectDrop={onCorrectDrop}
            onIncorrectDrop={onIncorrectDrop}
          />
        )}
        {showCompletedMessage && (
          <div className="pb-4 flex justify-center items-center flex-col text-white">
            <div className="font-semibold text-2xl mb-2">Great Job!</div>
            <img src={checkCheckPng} />
          </div>
        )}
      </div>
    </div>
  );
}

export default JigsawPuzzleIntervention;
