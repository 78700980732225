// prettier-ignore
// export default ['E U e f A B c I D i', 'y N F s g P G d S x', 't p T H a m R j n b', 'p T U r v R j n b M'];

export default [
  "o r u v C M O L h k",
  "E U e f A B c I D i",
  "y N F s g P G d S x",
  "t p T H a m R j n b",
  "E L h g x t m S O o",
  "p T U r v R j n b M",
  "e c A H i f I B s k",
  "N G d y P F a u C D",
  "o h c b r v e p f a",
  "k d g s i u t m n y",
  // 10
  "t r c g T M B G s v",
  "N U e p A D f C H a",
  "y P F d b R j n I x",
  "i m S O o u E L h k",
  "N j r b x e u A H o",
  "p S L n k M U s g P",
  "i m T D a f I B h v",
  "E G d y R F t c C O",
  "o r m k h v t p c e",
  "g d b s i f a u n y",
  // 20
  "o s c g A E O U n k",
  "R j a u I H m C B i",
  "v N F r b M L d T D",
  "t f S x e p P G h y",
  "E F h g B i m C O e",
  "u S U r v P j d b N",
  "a c A x o f T H n k",
  "M L s y R G t p I D",
  "a d c v n g e u m o",
  "y s b h t p i f r k",
  // 30
];
