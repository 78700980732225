import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import Window from "../Window";
import { RichAvatar } from "../Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import { useConversation } from "../../hooks/useConversation";
import { useInterventions } from "../../context/InterventionsContext";
import {
  IIntervention,
  InterventionStatus,
} from "../../context/InterventionsContext/InterventionsContext";
import StepsProgressBar from "../StepsProgressBar";
import phrasesMock from "../../mock/phrases";
import { useLesson } from "../../context/LessonContext";
import MicButton from "../MicButton";
import { useSpeech } from "../../context/SpeechContext";
import checkCheckPng from "../../assets/check-check.png";
import { wrapPhoneme } from "../../utils/avatarMessage";
import { successMessage } from "../../utils/avatarPhrases";
import { dictionary } from "cmu-pronouncing-dictionary";
import Spinner from "../Spinner";
import { useWordGraphemes } from "../../hooks/useWordGraphemes";
import { getChatCompletion } from "../../utils/api";

const INPUT_RANGE_STYLE = {
  boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset",
  borderImageSource:
    "radial-gradient(69.73% 69.73% at 50% 50%, #FCFCFC 52.8%, #E6E6E6 100%)",
  backgroundSize: "cover",
  backgroundOrigin: "border-box",
};

const step = 1; // 100 / (syllables.length + 1); // 10;
const min = 0;
const max = 100;

const getValueByIndex = (parts: string[], index: number) => {
  const progress = ((index + 1) / (parts.length + 1)) * 100;
  const pieceSize = Math.floor(((1 / (parts.length + 1)) * 100) / 3);
  const modifier = progress === 50 ? 0 : progress < 50 ? -pieceSize : pieceSize;
  return Math.floor(progress) + modifier;
};

export const DragSpeakIntervention = ({ id }: { id: string }) => {
  const totalLessons = phrasesMock.length - 1;
  const [isSliderEnabled, setSliderEnabled] = useState(true);
  const [interventionLoaded, setInterventionLoaded] = useState(false);
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const inputEl = useRef<any>();
  const [remaining, setRemaining] = useState<
    {
      syllable: string;
      phoneme: string;
      index: number;
    }[]
  >([]);
  const current = remaining[0];
  const { lessonStep, blockUserSpeech, setBlockUserSpeech } = useLesson();
  const { say, isAvatarSpeaking } = useAvatar();

  const { currentSpeechResult, setTempFlag } = useSpeech();
  const { interventions, setInterventions } = useInterventions();
  const currentIntervention = interventions.find(
    (intervention: IIntervention) => {
      return intervention.id === id;
    }
  )!;
  const { word } = currentIntervention;
  const phonemes = dictionary[word]
    .replace(/\d/g, "")
    .split(" ")
    .map((phoneme) => phoneme.toLowerCase());

  const [textParts, setTextParts] = useState<string[]>([]);

  const [syllablesLoading, setSyllablesLoading] = useState(true);
  const { loading: graphemesLoading, graphemes } = useWordGraphemes(word);
  const loading = graphemesLoading || syllablesLoading;

  useEffect(() => {
    setSliderValue(0);
    setBlockUserSpeech(true);
    setTextParts([]);
  }, [word]);

  useEffect(() => {
    if (graphemes.length === 0) {
      return;
    }
    // console.log(currentIntervention);
    // console.log(">>>", phonemes, graphemes, remaining);

    setSyllablesLoading(true);
    getChatCompletion(
      `Give me the syllables for the word "${word}". Return in JSON format { result: string[] }.`
    )
      .then((result) => {
        const originalSyllables: string[] = JSON.parse(
          result.data.choices[0].message.content
        ).result;
        const syllables = [...originalSyllables];
        const syllablesPhonemes: string[] = [];
        let currentSyllable = syllables.shift()!;
        let agg = "";
        graphemes.forEach((grapheme, index) => {
          if (!currentSyllable) {
            return;
          }
          let hasMatch = true;
          while (hasMatch) {
            if (!currentSyllable) {
              break;
            }
            hasMatch = false;
            if (currentSyllable.startsWith(grapheme)) {
              agg += phonemes[index] + " ";
              currentSyllable = currentSyllable.slice(grapheme.length);
              hasMatch = true;
            } else if (grapheme.length > 1) {
              const parts = grapheme.split("");
              if (
                parts.every((part) => part === part[0]) &&
                currentSyllable.startsWith(parts[0])
              ) {
                agg += phonemes[index] + " ";
                currentSyllable = currentSyllable.slice(1);
                hasMatch = true;
              }
            }
            if (currentSyllable.length === 0) {
              currentSyllable = syllables.shift()!;
              hasMatch = true;
              if (agg) {
                syllablesPhonemes.push(agg);
                agg = "";
              }
            }
          }
        });
        // console.log("-->", syllablesPhonemes);
        if (originalSyllables.length > 1) {
          setTextParts(originalSyllables);
          setRemaining(
            originalSyllables.map((item, index) => {
              return {
                syllable: item,
                phoneme: syllablesPhonemes[index],
                index: getValueByIndex(originalSyllables, index),
              };
            })
          );
        } else {
          setTextParts(graphemes);
          setRemaining(
            graphemes.map((item, index) => {
              return {
                syllable: item,
                // phoneme: item,
                phoneme: phonemes[index],
                index: getValueByIndex(graphemes, index),
              };
            })
          );
        }
      })
      .finally(() => {
        setSyllablesLoading(false);
      });
  }, [graphemes]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!current && !isAvatarSpeaking && sliderValue === max) {
        setBlockUserSpeech(false);
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sliderValue, isAvatarSpeaking, current]);

  const updateVisualProgress = (actualValue: number) => {
    const sliderEl = inputEl.current;
    const progress = (actualValue / max) * 100;
    sliderEl.style.background = `linear-gradient(to right, #00aff5 ${progress}%, #fff ${progress}%)`;
  };

  const onValueChange = (e: any) => {
    const inputValue = Number(e.target.value);
    if (inputValue < sliderValue) {
      return;
    }
    let newValue =
      inputValue > sliderValue + step ? sliderValue + step : inputValue;

    if (!current) {
      newValue = max;
      say(`Now it’s your turn! Try saying the word “${word}”`);
    } else if (current.index <= newValue) {
      setRemaining(([, ...newRemaining]) => newRemaining);
      say(wrapPhoneme(current.phoneme, "sapi"), {
        prosody: { rate: "-30%" },
      });
    }
    setSliderValue(newValue);
    updateVisualProgress(newValue);
  };

  const updateInterventions = () => {
    const updatedInterventions = interventions.map((intervention: any) => {
      if (intervention.id === id) {
        return {
          ...intervention,
          status: InterventionStatus.COMPLETED,
        };
      }
      return intervention;
    });
    setInterventions([...updatedInterventions]);
  };

  const avatarMessage = `Let's practice the word "${word}". Drag the slider across the word to hear how the sounds come together to make the word`;
  const { cancelConversation } = useConversation(
    {
      queue: [
        () => {
          setBlockUserSpeech(true);
          setSliderEnabled(false);
          say(avatarMessage, { prosody: { rate: "-10%" } });
        },
        () => {
          setSliderEnabled(true);
          // tracker.startCountdown();
        },
      ],
    },
    word
  );

  useEffect(() => {
    if (!interventionLoaded) {
      setInterventionLoaded(true);
      return;
    }

    const gotWordRightAtLeastOnce =
      currentSpeechResult.length > 0 &&
      currentSpeechResult.some(
        (result: any) =>
          result.PronunciationAssessment.ErrorType !== "Mispronunciation" &&
          result.PronunciationAssessment.AccuracyScore >= 80
      );

    if (gotWordRightAtLeastOnce) {
      setShowCompletedMessage(true);
      setTempFlag(false);
      cancelConversation();
      say(successMessage());
      setTimeout(() => {
        setShowCompletedMessage(false);
        updateInterventions();
      }, 2000);
    } else {
      if (!blockUserSpeech) {
        say(`Not quite. Let's try again`);
      }
    }
  }, [currentSpeechResult]);

  return (
    <div className="flex flex-col">
      <Window noPadding className="w-[690px] py-6 px-8 mt-4">
        <div className="flex">
          <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
            <RichAvatar size="120" message={avatarMessage} />
          </div>
          <div className="w-full flex flex-col">
            <StepsProgressBar
              steps={totalLessons + 1}
              currentStep={lessonStep}
            />

            <div className="flex justify-between align-center h-full">
              <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                <span className="">Practice</span>
              </div>
              <MicButton
                blockUserSpeech={blockUserSpeech}
                micButtonIsEnabled={true}
              />
            </div>
          </div>
        </div>
      </Window>
      <Window
        className={`mt-4 !px-10 w-[690px] h-full justify-center items-center ${
          showCompletedMessage
            ? "bg-green-gradient border-dark-green"
            : "bg-white-gradient border-semi-white"
        }`}
      >
        {showCompletedMessage ? (
          <div className="pb-4 flex justify-center items-center flex-col min-h-[50%]">
            <div className="font-semibold text-2xl mt-10 mb-2 text-white">
              Great Job!
            </div>
            <div className="font-semibold text-6xl mb-4 text-white">{word}</div>
            <img src={checkCheckPng} />
          </div>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <div className="w-full flex justify-between text-4xl mb-10">
              <div />
              {/* {graphemes.map((grapheme, index) => { */}
              {textParts.map((textPart, index) => {
                // console.log(index + 1, "/", graphemes.length + 1, "=");
                return (
                  <div
                    key={index}
                    style={
                      !current ||
                      current.index > getValueByIndex(textParts, index)
                        ? {
                            backgroundImage:
                              "linear-gradient(180deg, #00D0FF 0%, #0067F5 100%)",
                            color: "transparent",
                            backgroundClip: "text",
                          }
                        : {}
                    }
                  >
                    {textPart}
                  </div>
                );
              })}
              <div />
            </div>
            <div className="flex items-center w-full h-[80px]">
              <input
                ref={inputEl}
                type="range"
                min={min}
                max={max}
                step={step}
                value={sliderValue}
                onChange={onValueChange}
                disabled={!isSliderEnabled}
                // onInput={progressScript}
                className="drag-speak-slider w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed disabled:select-none"
                style={INPUT_RANGE_STYLE}
              ></input>
            </div>
          </>
        )}
      </Window>
    </div>
  );
};
