import { useEffect, useRef, useState } from "react";

export const useCountdown = (durationInSec: number, allowNegative = false) => {
  const [durationRemaining, setDurationRemaining] =
    useState<number>(durationInSec);
  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

  const stopCountdown = () => {
    clearTimeout(timeoutId.current);
    timeoutId.current = undefined;
  };

  useEffect(() => {
    return () => {
      stopCountdown();
    };
  }, []);

  const scheduleTick = () => {
    timeoutId.current = setTimeout(() => {
      setDurationRemaining((durationRemaining) => {
        const newValue = durationRemaining - 1;
        if (allowNegative) {
          return newValue;
        }
        if (newValue < 1) {
          stopCountdown();
        }
        return newValue < 0 ? 0 : newValue;
      });
      scheduleTick();
    }, 1000);
  };

  const startCountdown = () => {
    const isRunning = Boolean(timeoutId.current);
    if (isRunning) {
      return;
    }
    scheduleTick();
  };

  const resetCountdown = () => {
    stopCountdown();
    setDurationRemaining(durationInSec);
  };

  return {
    durationRemaining,
    startCountdown,
    stopCountdown,
    resetCountdown,
  };
};
