import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../PageLayout";
import PhraseVisualizer from "../PhraseVisualizer";
import { useEffect, useRef, useState } from "react";
import phrasesMock from "../../mock/letters";

import { useSpeech } from "../../context/SpeechContext";
import { useLesson } from "../../context/LessonContext";
import Window from "../Window";
import { RichAvatar } from "../Avatar/RichAvatar";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import Spinner from "../Spinner";
import ondulationBgPurple from "../../assets/ondulationBgPurple.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import StepsProgressBar from "../StepsProgressBar";
import { cleanString } from "../../utils/string";
import MicButton from "../MicButton";
import { useCountdown } from "../../hooks/useCountdown";
import { progressionMessage } from "../../utils/avatarPhrases";
import { useConversation } from "../../hooks/useConversation";

const totalLessons = phrasesMock.length - 1;

function NamingLetters() {
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const skipIntro = Boolean(
    params.get("skipIntro") && params.get("skipIntro") === "true"
  );

  const [micButtonIsEnabled, setMicButtonIsEnabled] = useState<boolean>(
    // !!skipIntro
    false
  );
  const [showLoadButton, setShowLoadButton] = useState(false);
  const errorsCount = useRef(0);

  const navigate = useNavigate();
  const {
    setLessonPhrase,
    lessonPhrase,
    lessonStep,
    setLessonStep,
    blockUserSpeech,
  } = useLesson();

  const {
    setCurrentPhrase,
    currentSpeechResult,
    setNextWord,
    setNextWordIndex,
    nextWordIndex,
    resetAllStates,
    handleStopRecording,

    recording,
    // timer,
    setIsRecognizingLetters,
  } = useSpeech();

  useEffect(() => {
    setIsRecognizingLetters(true);
    setNextWord(cleanString(phrasesMock[lessonStep]).split(" ")[0]);
    setNextWordIndex(-1);
    setLessonPhrase(cleanString(phrasesMock[lessonStep].toLowerCase()));
    setTimeout(() => {
      setShowLoadButton(true);
    }, 6000);
  }, []);

  useEffect(() => {
    if (!lessonPhrase) return;
    setCurrentPhrase(lessonPhrase);
    setNextWord(lessonPhrase.split(" ")[0]);
    setNextWordIndex(-1);
  }, [lessonPhrase]);

  const countdown = useCountdown(60, true);

  const onFinished = () => {
    handleStopRecording();
    // timer.stopTimer();
    countdown.stopCountdown();
    setIsRecognizingLetters(false);
    navigate(`/naming-letter-score${queryString}`);
  };

  useEffect(() => {
    const totalWords = lessonPhrase.split(" ").length;

    if (nextWordIndex === totalWords - 1 && lessonPhrase?.length > 0) {
      const isFirstRowCompletelyWrong =
        lessonStep === 0 && errorsCount.current === totalWords;
      if (lessonStep >= totalLessons || isFirstRowCompletelyWrong) {
        onFinished();
        return;
      }

      resetAllStates();

      setLessonPhrase(phrasesMock[lessonStep + 1]);
      setCurrentPhrase(phrasesMock[lessonStep + 1]);
      setLessonStep(lessonStep + 1);

      return;
    }
  }, [nextWordIndex, currentSpeechResult]);

  useEffect(() => {
    if (recording) {
      // timer.startTimer();
      countdown.startCountdown();
    }
  }, [recording]);

  useEffect(() => {
    // console.log("TIMER", countdown.durationRemaining);
    if (countdown.durationRemaining + 0.5 * lessonStep <= 0) {
      onFinished();
    }
  }, [countdown.durationRemaining]);

  const letterCountdown = useCountdown(5);
  useEffect(() => {
    if (!recording) {
      return;
    }
    letterCountdown.resetCountdown();
    letterCountdown.startCountdown();
    return () => {
      letterCountdown.resetCountdown();
    };
  }, [recording, nextWordIndex]);
  useEffect(() => {
    if (letterCountdown.durationRemaining <= 0) {
      errorsCount.current += 1;
      say(progressionMessage());
      setNextWord(lessonPhrase.split(" ")[1]);
      setNextWordIndex(nextWordIndex + 1);
      letterCountdown.resetCountdown();
    }
  }, [nextWordIndex, letterCountdown.durationRemaining]);

  const { isConnected, say } = useAvatar();
  useConversation({
    queue: skipIntro
      ? [
          () => {
            setMicButtonIsEnabled(true);
          },
        ]
      : [
          () => {
            say(
              `Hello! We're going to do an activity where you're going to name some letters and I'm going to listen. You're going to name as many letters as you can. Press the start button and read each letter name out loud for me to hear.`
            );
          },
          () => {
            setMicButtonIsEnabled(true);
          },
        ],
  });

  return (
    <PageLayout className="pt-20 bg-[#6d09fc]">
      {!isConnected ? (
        <div className="mt-12">
          {showLoadButton ? (
            <button
              onClick={() => {
                setShowLoadButton(false);
              }}
              className="mt-4 border-none w-fit rounded-full border-[2px] text-white font-bold text-2xl bg-gradient-to-b from-[#00D0FF] to-[#0067F5] bg-opacity-30 shadow-xl"
            >
              Click to Start Lesson!
            </button>
          ) : (
            <Spinner className="!w-[100px] !h-[100px]" />
          )}
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center w-full">
            <Window noPadding className="w-[690px] py-6 px-8 mb-4">
              <div className="flex">
                <div className="mr-8 w-[125px] h-[129px] border-t-[2px] border-r-[2px] border-b-[16px] border-l-[2px] border-[#3800D7] rounded-full">
                  <RichAvatar size="120" message={phrasesMock[lessonStep]} />
                </div>
                <div className="w-full flex flex-col">
                  <StepsProgressBar
                    steps={totalLessons + 1}
                    currentStep={lessonStep}
                  />

                  <div className="flex justify-between align-center h-full">
                    <div className="self-center h-[48px] flex justify-center items-center min-w-[136px] text-white text-xl font-bold rounded-xl border border-white/90 bg-white/5">
                      <span className="">
                        Exercise {parseInt(lessonStep as string) + 1}
                      </span>
                    </div>
                    <MicButton
                      blockUserSpeech={blockUserSpeech}
                      micButtonIsEnabled={micButtonIsEnabled}
                    />
                  </div>
                </div>
              </div>
            </Window>
            <Window
              noPadding
              className="p-8 bg-white-gradient border-semi-white w-[690px] h-[80%] justify-center items-center"
            >
              <PhraseVisualizer
                width="500"
                lessonPhrase={phrasesMock[lessonStep]}
              />
            </Window>
          </div>
        </>
      )}
      <img src={ondulationBgPurple} className="absolute bottom-0 bg-red" />
      <img src={cubesBottomRight} className="absolute right-0 bottom-0 z-1" />
    </PageLayout>
  );
}

export default NamingLetters;
