const SUCCESS_MESSAGES = [
  "Nice work!",
  "Great job!",
  "Excellent!",
  "That's it!",
  "You got it!",
];

const PROGRESSION_MESSAGES = [
  "Keep going",
  "Let's move on",
  "Let's carry on",
  "Let's proceed",
];

export const successMessage = () => {
  const messageIndex = Math.floor(Math.random() * SUCCESS_MESSAGES.length);
  return SUCCESS_MESSAGES[messageIndex];
};

export const progressionMessage = () => {
  const messageIndex = Math.floor(Math.random() * PROGRESSION_MESSAGES.length);
  return PROGRESSION_MESSAGES[messageIndex];
};
