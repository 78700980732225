import { useEffect, useRef, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { useSpeech } from "../../context/SpeechContext";
import { RichAvatar } from "../../components/Avatar/RichAvatar";
import ondulationBgGray from "../../assets/ondulationBgGray.svg";
import cubesBottomRight from "../../assets/cubes_bottom_right.png";
import { useAvatar } from "alpha-ai-avatar-sdk-react";
import ScoreCardFlat from "../FullScore/components/ScoreCardFlat";
import ScoreCard from "../FullScore/components/ScoreCard";
import Window from "../../components/Window";
import { calculateOverallPronunciationScore } from "../../utils/codeFromAzure";
import { cleanString } from "../../utils/string";
import Spinner from "../../components/Spinner";
import phrases from "../../mock/letters";
import { useLesson } from "../../context/LessonContext";
import GoHomeButton from "../../components/GoHomeButton";
import { useLocation } from "react-router-dom";

function NamingLettersScore() {
  // const timer = useTimer();
  const location = useLocation();
  const queryString = location.search;
  const { analyzedWordsWithErrors } = useSpeech();
  const [loading, setLoading] = useState(true);
  const { lessonPhrase } = useLesson();
  const [result, setResult] = useState<any>([]);
  const allPhrasesCombined = phrases.join(" ");

  const goHome = () => {
    window.location.href = `/${queryString}`;
  };

  // Redirect to home if user didn't come to this route from a assessment
  useEffect(() => {
    if (!lessonPhrase) {
      goHome();
    }
  }, []);

  useEffect(() => {
    // if (analyzedWordsWithErrors.words.length !== 0) {
    const result = calculateOverallPronunciationScore({
      allWords: structuredClone(analyzedWordsWithErrors.words ?? []),
      currentText: structuredClone(analyzedWordsWithErrors.words ?? []).map(
        (item: any) => item.Word
      ),
      language: "en-AU",
      reference_text: cleanString(allPhrasesCombined),
    });

    setResult(result);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // }
  }, [analyzedWordsWithErrors.words]);
  const totalOmissions = result.filter(
    (item: any) => item.PronunciationAssessment.ErrorType === "Omission"
  ).length;

  console.log("totalOmissions", totalOmissions);

  const { isConnected, say } = useAvatar();
  const isInitialized = useRef(false);

  const avatarMessage =
    "Congratulations, you completed the lesson! I knew you could do it! Click home and return to the main menu.";

  useEffect(() => {
    if (isConnected && !isInitialized.current) {
      isInitialized.current = true;
      // @TODO setTimeout is needed to ensure `say` works consistently - it fails a lot of the times even though isConnected = true
      setTimeout(() => {
        say(avatarMessage);
      }, 300);
    }
  }, [isConnected]);

  // if (loading || !result || !result.length) {
  if (loading || !result) {
    return (
      <PageLayout className="items-center justify-center h-[100vh]">
        <Spinner className="!w-[100px] !h-[100px]" />
      </PageLayout>
    );
  }

  const correctWords = analyzedWordsWithErrors.words.filter(
    (item: any) => item.PronunciationAssessment.AccuracyScore > 75
  );
  const errors = analyzedWordsWithErrors.words.length - correctWords.length;

  const durationInMin = 1; // timer.durationInMin
  const durationInSec = 60; // timer.durationInMin
  const wcpm = !durationInMin
    ? "N/A"
    : Math.floor(correctWords.length / durationInMin);

  const durationParts: any = !durationInSec
    ? []
    : [Math.trunc(durationInSec / 60), durationInSec % 60];
  if (durationParts.length === 2) {
    durationParts[1] =
      durationParts[1] < 10 ? `0${durationParts[1]}` : durationParts[1];
  }

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgGray})`,
      }}
    >
      <PageLayout className="w-[100vw]">
        <Window noPadding className="min-w-[50%] mb-8 pb-2 px-4 mt-4">
          <div className="min-h-[400px] min-w-[800px] w-full text-black flex justify-center text-center my-4 flex-col">
            <div className="flex justify-between ml-[50px]">
              <div className="w-full">
                <div className="h-4" />

                <div className="w-full">
                  <ScoreCard label="Words Correct per Min." score={wcpm} />
                  <div className="grid grid-cols-3 gap-2 mt-4">
                    <ScoreCardFlat
                      label="Time"
                      score={durationParts.join(":")}
                    />
                    <ScoreCardFlat
                      label="Letter Pronounced Correctly"
                      score={correctWords.length}
                    />
                    <ScoreCardFlat label="Errors" score={errors} />
                  </div>
                </div>
              </div>
              <div className="w-16" />
            </div>
          </div>
          <div className="relative flex justify-center items-center mb-8">
            <GoHomeButton />
          </div>
        </Window>

        <div className="mt-8 mb-4">
          <RichAvatar message={avatarMessage} />
        </div>
      </PageLayout>
    </div>
  );
}

export default NamingLettersScore;
